var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('dashboard-box',{attrs:{"back":true,"back-component-name":"management.fabric.index"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t(_vm.id != null ? "fabric.edit_fabric" : "fabric.create_fabric")))])]},proxy:true},{key:"preview",fn:function(){return [(_vm.fabricProxy)?_c('div',{staticClass:"row d-flex"},[_vm._l((_vm.fabricProxy.translations),function(value,index){return _c('div',{key:index,staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12"},[_c('text-input',{key:index,attrs:{"title":`${_vm.$t('fabric.fabric_name')}(${
                _vm.getLanguageById(value.language_id).name
              })`,"model":value.name,"placeholder":`${_vm.$t('fabric.please_enter_fabric_name')}(${
                _vm.getLanguageById(value.language_id).name
              })`,"is-valid":value != null && value.name.length !== 0 ? true : null},on:{"update:model":function($event){return _vm.$set(value, "name", $event)}}})],1)}),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"d-flex items-end justify-content-start"},[_c('custom-multi-select',{attrs:{"title":_vm.$t('expense.expense_logo_name'),"model":_vm.fabricProxy.logo_id,"options":_vm.convertArrayToObjectByKey(_vm.logoItems, 'logicalref', 'name'),"name":"fabric_logo_name","not-list":true,"is-inline":false,"max":1,"required":true,"item-per-row":1,"input-width":"auto","input-max-width":"200px"},on:{"update:model":function($event){return _vm.$set(_vm.fabricProxy, "logo_id", $event)}}})],1)]),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"d-flex items-end justify-content-start"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12"},[_c('custom-multi-select',{attrs:{"show-invalid":false,"options":_vm.units,"model":_vm.fabricProxy.unit_id,"title":_vm.$t('general.unit'),"name":"unit_id","max":1,"placeholder":_vm.$t('general.please_select_option'),"not-list":true,"is-inline":false},on:{"update:model":function($event){return _vm.$set(_vm.fabricProxy, "unit_id", $event)}}})],1)])]),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"cursor-pointer",domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.model_house.add', {
                class: 'w-20px h-20px d-inline-block object-cover'
              }))},on:{"onClick":function($event){return _vm.addOptionalOptions()}}})]),_c('div',{staticClass:"d-flex items-end justify-content-start"},[_c('custom-multi-select',{attrs:{"show-invalid":false,"title":_vm.$t('expense.expense_optional_logo_name'),"model":_vm.options.optional_logo_id,"options":_vm.convertArrayToObjectByKey(_vm.logoItems, 'logicalref', 'name'),"name":"fabric_optional_logo_name","not-list":true,"is-inline":false,"max":1,"required":true,"item-per-row":1,"input-width":"auto","input-max-width":"200px"},on:{"update:model":function($event){return _vm.$set(_vm.options, "optional_logo_id", $event)}}})],1)]),_c('div',{staticClass:"col-xl-3"},[_c('div',{staticClass:"d-flex items-end justify-content-start"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12"},[_c('custom-multi-select',{attrs:{"show-invalid":false,"options":_vm.units,"model":_vm.options.optional_unit_id,"title":_vm.$t('general.optional_unit'),"name":"optional_unit_id","max":1,"required":true,"placeholder":_vm.$t('general.please_select_option'),"not-list":true,"is-inline":false,"item-per-row":1},on:{"update:model":function($event){return _vm.$set(_vm.options, "optional_unit_id", $event)}}})],1)]),_c('div',{staticClass:"col-1"},[_c('span',{staticClass:"cursor-pointer",domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.sample.delete_red', {
                      class: 'w-20px h-20px d-inline-block object-cover'
                    }))},on:{"click":function($event){return _vm.deleteOptionalOptions(_vm.index)}}})])])],2):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('button-with-icon',{staticClass:"mr-3 danger-border-1px",attrs:{"text":_vm.$t('general.cancel'),"size":"lg","icon-name":null},on:{"onClick":function($event){return _vm.$router.go(-1)}}}),_c('button-with-icon',{attrs:{"text":_vm.$t(_vm.id != null ? 'general.save' : 'general.create'),"size":"lg","icon-name":null,"disabled":!_vm.isValidToCreate},on:{"onClick":_vm.createOrUpdateFabric}})],1)]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }